import { defineStore } from "pinia";
// main is the name of the store. It is unique across your application
// and will appear in devtools
const checkoutSession = useCookie('checkoutSession', { sameSite: true, secure: true})
export const useCheckoutStore = defineStore("checkout", {
	// a function that returns a fresh state
	state: () => ({
		tab: 1,
		cartDetails: {
			appointment_date: '',
			appointment_time: '',
	
	
		},
		
		maxTestLists: 4,
		directPaymentDetails: {},
		testLists: [],
		isGuest: false,
		steps: [
			{
				id: 1,
				slug: "date_and_time",
				title: useTranslateStore("checkout.step.dateTime"),
			},
			{
				id: 2,
				slug: "patient_details",
				title: useTranslateStore("checkout.step.customerDetail"),
			},
			{
				id: 3,
				slug: "payment",
				title: useTranslateStore("checkout.step.payment"),
			},
		],
		directPaymentSteps: [
			{
				id: 1,
				slug: "patient_details",
				title: useTranslateStore("checkout.step.customerDetail"),
			},
			{
				id: 2,
				slug: "payment",
				title: useTranslateStore("checkout.step.payment"),
			},
		],
		appointment_date: null,
		appointment_time: null,
		createOrderResponse: {},
		addressCompleteHasError: false,
		cardElement: null,
		stripeClientSecret: null,
		paymentType: "",
        start_point: "unknown",
	}),
	getters: {
		getCheckoutSession() {
			return (checkoutSession.value);
		}
	},
	actions: {
		setCollection(item) {
			let isExisted = this.testLists.some((data) => data.id == item.id);
			this.testLists = isExisted ? this.testLists.filter((i) => i.id !== item.id) : [...this.testLists, item];
		 },
		removeTestLists() {
			this.testLists = []
		},

		removeTestPanelLists(item) {
			this.testLists= this.testLists.filter((i) => i.id == item)
		},
		nextStep() {
			this.tab += 1;
		},
		navigate(payload) {
			this.tab = payload;
		},
		navigateAsGuest() {
			this.isGuest = true;
		},
		setAppointmentDate(value) {
			this.appointment_date = value;
		},
		setAppointmentTime(value) {
			this.appointment_time = value;
		},
		
		setSelectedClinicCookieData(clinicId, testId, startPoint) {
	    	checkoutSession.value = { 'clinic_id': clinicId, 'test_id': testId, 'start_point': startPoint, 'country_locale': useCookie("preferredCountry").value};
	   },
	   resetCheckoutCookieId() {
		checkoutSession.value = null
	   },
	
		setSelectedClinicId(value) {
			checkoutSession.value = { 'clinic_id': value }
		},
	
		setCartDetails(value) {
			this.cartDetails = value;
		},
		setCreateOrderResponse(value) {
			this.createOrderResponse = value;
		},
		setAddressCompleteHasError(value) {
			this.addressCompleteHasError = value;
		},
		setCardElement(value) {
			this.cardElement = value;
		},
		setStripeClientSecret(value) {
			this.stripeClientSecret = value;
		},
		setDirectPaymentDetails(value) {
			this.directPaymentDetails = value;
		},
		setPaymentType(value) {
			this.paymentType = value;
		},
	},
	persist: false,
},

);



